export default {
    member:{
        value:"member",
        label:"免单会员"
    },
    merchant:{
        value:"merchant",
        label:"商家"
    },
    merchantAgent:{
        value:"merchant-agent",
        label:"商家代理插件-商家代理"
    },
    wholesalerAgent:{
        value:"agent",
        label:"批发商插件-代理"
    },
    wholesalerBoss:{
        value:"boss",
        label:"批发商插件-老板"
    }
}